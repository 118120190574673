import { defineStore } from 'pinia'
import Tracker from '@openreplay/tracker'
import { APP_VERSION } from '@/config/constantes'

export const useUserStore = defineStore({
  id: 'user',
  state: (): {
    authRefreshTryed: boolean
    user: any
    token: any
  } => ({
    authRefreshTryed: false,
    user: undefined,
    token: undefined,
  }),
  actions: {
    connection({ user, token }: { user: any; token: any; }) {
      this.user = user
      this.token = token
      this.authRefreshTryed = false
      if (!window?.location.host.includes('127.0.0.1') && !window?.location.host.includes('localhost')) {
        if (user?.userId) {
          const tracker = new Tracker({
            projectKey: 'krsi3WGQFBi7QJZ8o8JN',
          })

          tracker.setUserID(user.userId.email)
          tracker.setMetadata('resourceId', user._id)
          tracker.setMetadata('name', `${user.lastname} ${user.firstname}`)
          tracker.setMetadata('profile', user.accessProfile.display)
          tracker.setMetadata('firm', user._firmId.company)
          tracker.setMetadata('_firmId', user._firmId._id)
          tracker.setMetadata('picture', user.picture)
          tracker.setMetadata('release', APP_VERSION)
        }
      }
    },
    disconnection() {
      this.user = undefined
      this.token = undefined
    },
  },
  //@ts-ignore
  persist: true,
})
