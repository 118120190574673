<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import bLabel from '../common/bLabel.vue'
import PhWarningDuotone from '~icons/ph/warning-duotone'

const props = defineProps<{
  menuItem: {
    isHeader: boolean
    prependIcon: any
    display: string
    route: string
    isAlpha: boolean
    isBeta: boolean
    params: any
    moduleId: number | string
    subPages: {
      isHeader: boolean
      prependIcon: any
      display: string
      route: string
      isAlpha: boolean
      isBeta: boolean
      params: any
      moduleId: number | string
    }[]
  }
}>()
const emit = defineEmits<{
  (event: 'click', ...args: any[]): void
}>()

const route = useRoute()

watch(() => route.path, () => {
  if (route.meta.moduleName !== props.menuItem.moduleName)
    isSubMenuOpen.value = false
})

const isSubMenuOpen = ref(false)
const clickOnMenu = (isHeader = false) => {
  if (isHeader)
    isSubMenuOpen.value = !isSubMenuOpen.value
  else emit('click', false)
}

const { t, locale } = useI18n()
</script>

<template>
  <li class="pr-2 pl-4 select-none">
    <router-link
      v-if="!menuItem.isHeader" :to="{ name: menuItem.route, params: menuItem.params }"
      class="menu-item" :class="[menuItem.color]" @click="clickOnMenu(true)"
    >
      <span class="inline-flex justify-center items-center ml-4">
        <component :is="menuItem.prependIcon" />
      </span>
      <span class="ml-2 text-sm tracking-wide truncate">{{ t(menuItem.display) }}</span>
    </router-link>
    <div v-else class="flex flex-row items-center cursor-pointer" :data-cy="`header-${menuItem.moduleName}`" @click="clickOnMenu(true)">
      <div class="tracking-wide flex uppercase whitespace-nowrap hover:text-primary-400" :class="[{ 'font-semibold transform scale-105': route.meta.moduleName === menuItem.moduleName }]">
        <span v-if="menuItem.prependIcon" class="inline-flex justify-center items-center mr-2">
          <component :is="menuItem.prependIcon" />
        </span>
        <p class="menu-item-show-hover md:opacity-0">
          {{ t(menuItem.display) }}
        </p>
      </div>
    </div>
    <transition name="fade" duration="200">
      <ul v-if="menuItem.subPages && menuItem.subPages.length > 0 && (isSubMenuOpen || route.meta.moduleName === menuItem.moduleName)" class="pt-2">
        <li
          v-for="(page) in menuItem.subPages" :key="`${page.route}-${page.params?.()}`"
          class="sub-menu-item border-l-2 ml-2 relative last:!border-0 last:after:-left-[0px] border-gray-500 after:transform after:-translate-y-1/1 after:top-0 after:bg-transparent after:w-6 after:h-6 after:border-l-2 after:border-b-2 after:rounded-bl-xl after:absolute after:left-[-2px] after:border-gray-500 after:border-0"
          @click="clickOnMenu()"
        >
          <router-link
            v-if="!page.isHeader" :data-cy="`${page.route}-${page.params?.()}`" :to="{ name: page.route, params: page.params?.() || {} }"
            class="menu-item gap-2" :class="[menuItem.color, { '!text-primary-500': route.name === page.route }]" @click="emit('click', false)"
          >
            <span class="sub-menu-item-icon inline-flex justify-center items-center opacity-0">
              <component :is="page.prependIcon" />
            </span>
            <span class="text-sm tracking-wide truncate">{{ t(page.display) }} </span>
            <b-label v-if="page.isAlpha" v-tooltip="'ALPHA, Bugs are likely to be present and missing features are expected'" bg-color="error-op" class="ml-auto" size="dense">
              <PhWarningDuotone />
            </b-label>
            <b-label v-if="page.isBeta" v-tooltip="'BETA, some bug could still be present and not critical features missing'" bg-color="warning-op" class="ml-auto" size="dense">
              <PhWarningDuotone />
            </b-label>
          </router-link>
        </li>
      </ul>
    </transition>
  </li>
</template>

<style>
.drawer-container:hover .menu-item-show-hover,
.drawer-container:hover .sub-menu-item-icon,
.drawer-container-fixed .menu-item-show-hover,
.drawer-container-fixed .sub-menu-item-icon {
  opacity: 1;
  display: flex;
  transition: all ease 0.5s;
}

.drawer-container:hover .sub-menu-item::after,
.drawer-container-fixed .sub-menu-item::after {
  content: '';
}
</style>
