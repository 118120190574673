import { defineStore } from 'pinia'
import type { TColors } from '@/services/application/interfaces'

type IError = {
  id?: string
  title?: string
  message?: string
  color: TColors
  duration?: number
}
type IState = {
  errors: Array<IError>
  drawer: {
    fixed: boolean
  }
}

// @ts-expect-error
export const useApplicationStore = defineStore('application', {
  // arrow function recommended for full type inference
  state: (): IState => {
    return {
      // all these properties will have their type inferred automatically
      errors: [
      ],
      drawer: {
        fixed: false,
      },
    }
  },
  actions: {
    removeError(error: { id: string }) {
      const index = this.errors.findIndex(currentError => currentError.id === error.id)
      this.errors.splice(index, 1)
    },
    pushError({ color = 'error', duration = 3000, ...error }: IError) {
      const id = `${new Date().getTime()}`
      this.errors.push({ ...error, id, color, duration })
    },
    resetErrors() {
      this.errors = []
    },
  },
  persist: true,
})
