import { RouteRecordRaw } from 'vue-router'
import authRoutes from "@/config/router/routes/authRoutes";
import trainingRoutes from "@/config/router/routes/trainingRoutes";
import hrRoutes from "@/config/router/routes/hrRoutes";
import projectRoutes from "@/config/router/routes/projectRoutes";
import recruitmentRoutes from "@/config/router/routes/recruitmentRoutes";
import applicationRoutes from '@/config/router/routes/applicationRoutes';
import dashboardRoutes from '@/config/router/routes/dashboardRoutes';
import documentationRoutes from '@/config/router/routes/documentationRoutes';
import superAdminRoutes from '@/config/router/routes/superAdminRoutes';
import timesheetRoutes from '@/config/router/routes/timesheetRoutes';
import leaveRoutes from '@/config/router/routes/leaveRoutes';
import salesRoutes from '@/config/router/routes/saleRoutes';
import managementRoutes from '@/config/router/routes/managementRoutes';
import evaluationRoutes from '@/config/router/routes/evaluationRoutes';
import invoiceRoutes from '@/config/router/routes/invoiceRoutes';

const defaultMeta = {
  requiresAuth: true,
  navbarNotRequired: false,
  moduleId: 0,
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      ...defaultMeta,
    },
  },
  {
    path: '/export',
    name: 'Export',
    component: () => import('@/views/export/Export.vue'),
    meta: {
      requiresAuth: true,
    },
  },

]

export default [
    ...routes,
    ...recruitmentRoutes,
    ...trainingRoutes,
    ...authRoutes,
    ...projectRoutes,
    ...hrRoutes,
    ...applicationRoutes,
    ...dashboardRoutes,
    ...documentationRoutes,
    ...superAdminRoutes,
    ...timesheetRoutes,
    ...leaveRoutes,
    ...salesRoutes,
    ...managementRoutes,
    ...evaluationRoutes,
    ...invoiceRoutes,
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/application/NotFound.vue') },
]
