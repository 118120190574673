import type { RouteRecordRaw } from 'vue-router'
import { guardHrAccess, guardResourceProfile } from '@/config/router/navigationsGuards/guardHr'

const MODULE_NAME = 'HR'
const MODULE_ID = 1

const hrRoutes: Array<RouteRecordRaw> = [
  {
    path: '/hr',
    name: 'HrBase',
    component: () => import('@/views/hrBase/HrBase.vue'),
    meta: {
      requiresAuth: true,
      titleTranslationKey: 'pageTitle.directory',
    },
    beforeEnter: [guardHrAccess],
  },
  {
    path: '/hr/:resourceId',
    name: 'EmployeeProfile',
    component: () => import('@/views/hrBase/EmployeeProfile.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [guardHrAccess, guardResourceProfile],
  },
]

export default hrRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
