import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IPaginated, IResource } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'recruitmentProcess'

const screeningList = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any >) => (query: { page?: number; limit?: number; display?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/inbox/paginated`,
    query,
  })
}

const updateRecruitmentProcess = (axios: IFactoryFunctionRequest<IResource, any >) => (process: unknown) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${process._id}`,
    data: process,
  })
}

const partialUpdateRecruitmentProcess = (axios: IFactoryFunctionRequest<IResource, any >) => (processId: string, process: unknown) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${processId}`,
    data: process,
  })
}

const candidateBaseList = (axios: IFactoryFunctionRequest<IPaginated<IResource>, any >) => (query: { cursor?: string; limit?: number; name?: string }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/getDatabaseProcesses`,
    query,
  })
}
const getProcessList = (axios: IFactoryFunctionRequest<IResource, any >) => (query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/kanban`,
    query,
  })
}
const getProcessInterviewPage = (axios: IFactoryFunctionRequest<IResource, any >) => (processId) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/interviews/${processId}`,
  })
}

const addCommentToProcess = (axios: IFactoryFunctionRequest<any, any>) => (processId, comment: any) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/addComment/${processId}`,
    data: comment,
  })
}

const addNoteToProcess = (axios: IFactoryFunctionRequest<any, any>) => (processId, note: any) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/addNote/${processId}`,
    data: note,
  })
}

const exportCandidatesToExel = (axios: IFactoryFunctionRequest<IResource, any >) => (data, query) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/export`,
    query,
    immediate: false,
  })
}

const getBase64CandidatePdf = (axios: IFactoryFunctionRequest<IResource, any >) => (processId, query) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/candidatePdf/${processId}`,
    query,
  })
}

const createInterviewProcess = (axios: IFactoryFunctionRequest<any, any >) => ({ processId, roundIndex, interview }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${processId}/rounds/${roundIndex}/interviews`,
    data: interview,
  })
}

const updateInterviewMeeting = (axios: IFactoryFunctionRequest<any, any >) => ({ processId, interview }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${processId}/interview/${interview._id}`,
    data: { interview },
  })
}

export default (axios: AxiosInstance) => ({
  screeningList: screeningList(useAxios(axios)),
  updateRecruitmentProcess: updateRecruitmentProcess(useAxios(axios)),
  candidateBaseList: candidateBaseList(useAxios(axios)),
  getProcessList: getProcessList(useAxios(axios)),
  exportCandidatesToExel: exportCandidatesToExel(useAxios(axios)),
  getProcessInterviewPage: getProcessInterviewPage(useAxios(axios)),
  getBase64CandidatePdf: getBase64CandidatePdf(useAxios(axios)),
  partialUpdateRecruitmentProcess: partialUpdateRecruitmentProcess(useAxios(axios)),
  addCommentToProcess: addCommentToProcess(useAxios(axios)),
  addNoteToProcess: addNoteToProcess(useAxios(axios)),
  createInterviewProcess: createInterviewProcess(useAxios(axios)),
  updateInterviewMeeting: updateInterviewMeeting(useAxios(axios)),
})
