<script lang="ts" setup>
import { useMagicKeys, watchDebounced } from '@vueuse/core'
import { ref } from 'vue'
import BButton from '../common/bButton.vue'
import BModal from '@/components/common/bModal.vue'
import MaterialSymbolsSearchRounded from '~icons/material-symbols/search-rounded'
import { searchApi } from '@/services/api'
import ResourceItem from '@/components/search/ResourceItem.vue'
import ProjectItem from '@/components/search/ProjectItem.vue'
import MaterialSymbolsScreenSearchDesktopOutlineRounded from '~icons/material-symbols/screen-search-desktop-outline-rounded'
import MaterialSymbolsSearchOffRounded from '~icons/material-symbols/search-off-rounded'

const isSearchOpen = ref(false)
const keys = useMagicKeys()
const shiftF = keys['ControlLeft+ShiftLeft+F']
const searchInput = ref()
const searchInputText = ref()

const searchList = ref({ resources: [] })

const searchRequest = searchApi.globalSearch()
watchDebounced(shiftF, async (v) => {
  openSearch()
}, { debounce: 250 })

const openSearch = async () => {
  isSearchOpen.value = !isSearchOpen.value
  if (isSearchOpen.value) {
    await new Promise(r => setTimeout(r, 5))
    searchInput.value.focus()
  }
}

watchDebounced(searchInputText, (v) => {
  const query = { searchString: v }
  searchRequest.refresh({ query }).then((res) => {
    console.table(res.resources)
    searchList.value = res
  })
}, { debounce: 350 })
</script>

<template>
  <BModal v-model="isSearchOpen">
    <template #header>
      Global search
    </template>
    <div class="relative">
      <MaterialSymbolsSearchRounded class="pointer-events-none absolute top-3.5 left-4 h-5 w-5" />
      <input
        ref="searchInput" v-model="searchInputText" aria-controls="options" aria-expanded="false"
        class="h-12 w-full outline-none bg-transparent pl-11 pr-4 placeholder-gray-400 sm:text-sm" placeholder="Search..."
        role="combobox" type="text"
      >
    </div>
    <template v-if="searchList.resources?.length > 0 || searchList.projects?.length > 0">
      <div class="flex divide-x divide-gray-100">
        <!-- Preview Visible: "sm:h-96" -->
        <div class="max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4">
          <!-- Default state, show/hide based on command palette state. -->
          <!--        <h2 class="mt-2 mb-4 text-xs font-semibold">Recent searches</h2>
          <ul id="recent" class="-mx-2 text-sm" role="listbox">
            &lt;!&ndash; Active: "bg-gray-100" &ndash;&gt;
            <li id="recent-1" class="group flex cursor-default select-none items-center rounded-md p-2" role="option" tabindex="-1">
              <img alt="" class="h-6 w-6 flex-none rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80">
              <span class="ml-3 flex-auto truncate">Floyd Miles</span>
              &lt;!&ndash; Not Active: "hidden" &ndash;&gt;
              &lt;!&ndash; Heroicon name: solid/chevron-right &ndash;&gt;
  &lt;!&ndash;            <svg aria-hidden="true" class="ml-3 hidden h-5 w-5 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path clip-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" fill-rule="evenodd" />
              </svg>&ndash;&gt;
            </li>
          </ul> -->

          <h2 class="mt-2 mb-4 text-xs font-semibold">
            Active resources
          </h2>

          <ul v-if="searchList.resources.length > 0" id="options" v-auto-animate class="-mx-2 text-sm" role="listbox">
            <!-- Active: "bg-gray-100" -->
            <ResourceItem
              v-for="resource in searchList.resources" :key="resource._id" :resource="resource"
              @open-search="openSearch"
            />
          </ul>
          <div v-else class="flex gap-4">
            <MaterialSymbolsSearchOffRounded class="text-2xl" />
            <p>No resource found</p>
          </div>
        </div>

        <!-- Active item side-panel, show/hide based on active state -->
        <!--      <div class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
          <div class="flex-none p-6 text-center">
            <img alt="" class="mx-auto h-16 w-16 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80">
            <h2 class="mt-3 font-semibold">Tom Cook</h2>
            <p class="text-sm leading-6">Director, Product Development</p>
          </div>
          <div class="flex flex-auto flex-col justify-between p-6">
            <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm">
              <dt class="col-end-1 font-semibold">Phone</dt>
              <dd>881-460-8515</dd>
              <dt class="col-end-1 font-semibold">URL</dt>
              <dd class="truncate"><a class="text-primary-600 underline" href="https://example.com">https://example.com</a></dd>
              <dt class="col-end-1 font-semibold">Email</dt>
              <dd class="truncate"><a class="text-primary-600 underline" href="#">tomcook@example.com</a></dd>
            </dl>
            <button class="mt-6 w-full rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" type="button">Send message</button>
          </div>
        </div> -->
      </div>
      <div class="flex divide-x divide-gray-100">
        <!-- Preview Visible: "sm:h-96" -->
        <div class="max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4">
          <!-- Default state, show/hide based on command palette state. -->
          <!--        <h2 class="mt-2 mb-4 text-xs font-semibold">Recent searches</h2>
          <ul id="recent" class="-mx-2 text-sm" role="listbox">
            &lt;!&ndash; Active: "bg-gray-100" &ndash;&gt;
            <li id="recent-1" class="group flex cursor-default select-none items-center rounded-md p-2" role="option" tabindex="-1">
              <img alt="" class="h-6 w-6 flex-none rounded-full" src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80">
              <span class="ml-3 flex-auto truncate">Floyd Miles</span>
              &lt;!&ndash; Not Active: "hidden" &ndash;&gt;
              &lt;!&ndash; Heroicon name: solid/chevron-right &ndash;&gt;
  &lt;!&ndash;            <svg aria-hidden="true" class="ml-3 hidden h-5 w-5 flex-none" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path clip-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" fill-rule="evenodd" />
              </svg>&ndash;&gt;
            </li>
          </ul> -->

          <h2 class="mt-2 mb-4 text-xs font-semibold">
            Active projects
          </h2>

          <ul v-if="searchList.projects.length > 0" id="options" v-auto-animate class="-mx-2 text-sm" role="listbox">
            <!-- Active: "bg-gray-100" -->
            <ProjectItem
              v-for="project in searchList.projects" :key="project._id" :project="project"
              @open-search="openSearch"
            />
          </ul>
          <div v-else class="flex gap-4">
            <MaterialSymbolsSearchOffRounded class="text-2xl" />
            <p>No project found</p>
          </div>
        </div>

        <!-- Active item side-panel, show/hide based on active state -->
        <!--      <div class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
          <div class="flex-none p-6 text-center">
            <img alt="" class="mx-auto h-16 w-16 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80">
            <h2 class="mt-3 font-semibold">Tom Cook</h2>
            <p class="text-sm leading-6">Director, Product Development</p>
          </div>
          <div class="flex flex-auto flex-col justify-between p-6">
            <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm">
              <dt class="col-end-1 font-semibold">Phone</dt>
              <dd>881-460-8515</dd>
              <dt class="col-end-1 font-semibold">URL</dt>
              <dd class="truncate"><a class="text-primary-600 underline" href="https://example.com">https://example.com</a></dd>
              <dt class="col-end-1 font-semibold">Email</dt>
              <dd class="truncate"><a class="text-primary-600 underline" href="#">tomcook@example.com</a></dd>
            </dl>
            <button class="mt-6 w-full rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" type="button">Send message</button>
          </div>
        </div> -->
      </div>
    </template>

    <div v-else class="flex items-center justify-center flex-col">
      <MaterialSymbolsScreenSearchDesktopOutlineRounded class="text-2xl" />
      <p class="mt-4 font-semibold">
        Search into your database
      </p>
      <p class="mt-2">
        Start typing to search into you resources, projects (more will be available)
      </p>
    </div>
  </BModal>
  <BButton class="mx-2 hidden md:flex" bg="base" @click="openSearch">
    <MaterialSymbolsSearchRounded class="h-5 w-5" />
    <span class="search-command-text whitespace-nowrap">
      ctrl + shift + f
    </span>
  </BButton>
</template>

<style scoped>
@keyframes screen-search {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.drawer-container-fixed .search-command-text,
.drawer-container:hover .search-command-text{

  animation: forwards screen-search 0.5s;
  opacity: 0;
  display:flex;
}
.search-command-text{
  display: none;
}
</style>
