import { createRouter, createWebHistory } from 'vue-router'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import routes from '@/config/router/routes'
import initNavigationGuards from '@/config/router/navigationsGuards/navigationGuards'

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.isReady().then(async () => {
  useRegisterSW({ immediate: true })
})

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module'))
    window.location = to.fullPath
})

const routerWithNavigationGuards = initNavigationGuards(router)

export default routerWithNavigationGuards
