import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, IPaginated } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'jobOfferStatistics'

const viewCount = (axios: IFactoryFunctionRequest<{ viewCount: number }, { viewCount: number }>) => ({ offerId }: { offerId: string }): IFactoryResponse<{ viewCount: number }, { viewCount: number }> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/viewCount/${offerId}`,
  })
}

export default (axios: AxiosInstance) => ({
  viewCount: viewCount(useAxios(axios)),
})
