import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'
import { useUserStore } from '@/config/store/userStore'

let userStore
const getConnectedUser = () => {
  if (!userStore)
    userStore = useUserStore()
  return userStore
}

const resource = 'leaves'

const getInterval = (axios: IFactoryFunctionRequest<any, any>) => ({
  startDate,
  endDate,
  resources = [],
}: { startDate: Date; endDate: Date; resources?: string[] }): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/firmInterval/${getConnectedUser().user._firmId._id}/global`,
    data: { startDate, endDate, resources },
  })
}

const getProjectLeaves = (axios: IFactoryFunctionRequest<any, any>) => ({ projectId }: { projectId: string }): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/showProjectLeaves/${projectId}`,
  })
}
const getResource = (axios: IFactoryFunctionRequest) => (id: string): IFactoryResponse<any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/resource/${id}`,
  })
}

const getLeavesBetweenDatesForResources = (axios: IFactoryFunctionRequest<any, any>) => ({
  resourceId,
  startDate,
  endDate,
}: { resourceId: string[]; startDate: Date; endDate: Date }): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/betweenDates/forResources`,
    data: { resourceId, startDate: startDate.toDateString(), endDate: endDate.toDateString() },
  })
}
const getLeavesRequests = (axios: IFactoryFunctionRequest<any, any>) => (query: any): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
    query,
  })
}

const getLeavesCalendar = (axios: IFactoryFunctionRequest<any, any>) => (query: any): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/calendar`,
    query,
  })
}

const updateLeave = (axios: IFactoryFunctionRequest<any, any>) => (leave): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
    data: leave,
  })
}

const getLeaveRequest = (axios: IFactoryFunctionRequest<any, any>) => (leaveId): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${leaveId}`,
  })
}

const createNewLeaveRequest = (axios: IFactoryFunctionRequest<any, any>) => (payload): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/`,
    data: payload,
  })
}
const createNewBulkLeaveRequest = (axios: IFactoryFunctionRequest<any, any>) => (payload): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/create/bulk`,
    data: payload,
  })
}

const retreiveLeaveBetweenDates = (axios: IFactoryFunctionRequest<{ _id: string; firstname: string; lastname: string; userId: string; user: { email: string } }, any>) => (resourceId: string, startDate: Date, endDate: Date) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${resourceId}/betweenDate/${startDate}/${endDate}`,
  })
}

export default (axios: AxiosInstance) => ({
  getInterval: getInterval(useAxios(axios)),
  getProjectLeaves: getProjectLeaves(useAxios(axios)),
  getLeavesBetweenDatesForResources: getLeavesBetweenDatesForResources(useAxios(axios)),
  getLeavesRequests: getLeavesRequests(useAxios(axios)),
  updateLeave: updateLeave(useAxios(axios)),
  getLeavesCalendar: getLeavesCalendar(useAxios(axios)),
  getLeaveRequest: getLeaveRequest(useAxios(axios)),
  createNewLeaveRequest: createNewLeaveRequest(useAxios(axios)),
  getResource: getResource(useAxios(axios)),
  createNewBulkLeaveRequest: createNewBulkLeaveRequest(useAxios(axios)),
  retreiveLeaveBetweenDates: retreiveLeaveBetweenDates(useAxios(axios)),
})
