import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'dashboards'

const globalAnnualProduction = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/annualProduction`,
    query: options,
  })
}
const globalNetProfit = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/netProfit`,
    query: options,
  })
}
const wonProjects = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<number, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/wonProjects`,
    query: options,
  })
}
const countResource = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<number, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/countResources`,
    query: options,
  })
}
const projectMargin = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<{ averageMargin: number }, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/averageMargin`,
    query: options,
  })
}
const projectSold = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/sales`,
    query: options,
  })
}
const realisatedVersusObjective = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<{ _createdAt: string; datas: { value: number; average: number; objective: number; projects: any[] } }, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/realisatedVersusObjective`,
    query: options,
  })
}
const annualSalesPerMonth = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<{ _createdAt: string; datas: any }, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/annualSalesPerMonth`,
    query: options,
  })
}
const cashFlow = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<{ _createdAt: string; datas: any }, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/cashFlow`,
    query: options,
  })
}

const staffingRateByMonth = (axios: IFactoryFunctionRequest<any, any>) => (options: { year: number }): IFactoryResponse<Array<any>, any> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/staffingRate`,
    query: options,
  })
}
export default (axios: AxiosInstance) => ({
  globalAnnualProduction: globalAnnualProduction(useAxios(axios)),
  globalNetProfit: globalNetProfit(useAxios(axios)),
  wonProjects: wonProjects(useAxios(axios)),
  projectMargin: projectMargin(useAxios(axios)),
  projectSold: projectSold(useAxios(axios)),
  annualSalesPerMonth: annualSalesPerMonth(useAxios(axios)),
  realisatedVersusObjective: realisatedVersusObjective(useAxios(axios)),
  cashFlow: cashFlow(useAxios(axios)),
  countResource: countResource(useAxios(axios)),
  staffingRateByMonth: staffingRateByMonth(useAxios(axios)),
})
