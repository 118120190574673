<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'
import BButton from '@/components/common/bButton.vue'

const {
  offlineReady,
  needRefresh,
  updateServiceWorker,
} = useRegisterSW()
</script>

<template>
  <BButton v-if="needRefresh" @click="updateServiceWorker()">
    Reload new version
  </BButton>
</template>
